//abstracts
@import 'abstracts/variables';
@import 'abstracts/base';
@import 'abstracts/utilities';
@import 'abstracts/colors';
@import 'abstracts/text';

// Pages
@import 'pages/plans';
@import 'pages/employees';
@import 'pages/settings';
@import 'pages/employeeSignUp';
@import 'pages/wallet';
@import 'pages/404';
@import 'pages/enterprise';

// Components
@import 'components/input';
@import 'components/button';
@import 'components/dashboardSidebar';
@import 'components/dashboardHeader';
@import 'components/table';
@import 'components/sidemodal';
@import 'components/modal';
@import 'components/uploadFile';
@import 'components/phone';

// Layout
@import 'layout/authlayout';
@import 'layout/dashboardLayout';

// .slick-prev {
// 	z-index: 1;
// 	left: 25px !important;
// 	&::before {
// 		cursor: pointer;
// 		font-size: 50px !important;
// 		color: rgba(255, 255, 255, 1) !important;
// 		border-radius: 50%;
// 	}
// }
// .slick-next {
// 	z-index: 1;
// 	right: 60px !important;

// 	cursor: pointer !important;
// 	&::before {
// 		content: '' !important;
// 		font-size: 50px !important;
// 		background-color: red;
// 		color: rgba(255, 255, 255, 1) !important;
// 		border: 1px solid #d3d3d399;
// 		border-radius: 50%;
// 	}
// }

.prevBtn {
	background-color: #005397 !important;
	width: 28px;
	height: 28px;
	background-image: url('../assets/svgs/rigthCaret.svg');
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	border-radius: 50%;
	border: 1px solid #d3d3d399;
	position: absolute;
	top: 170px;
	right: 40px;
	transform: rotate(180deg);
	cursor: pointer;

	transition: all 0.4s;

	&:hover,
	&:focus {
		background-color: darken(#005397, 3%) !important;
	}
}
.nextBtn {
	background-color: #005397 !important;
	width: 28px;
	height: 28px;
	background-image: url('../assets/svgs/rigthCaret.svg');
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	border-radius: 50%;
	border: 1px solid #d3d3d399;
	position: absolute;
	z-index: 1000000;
	// top: 60px;
	// right: 40px;
	top: 170px;
	right: 0px;

	cursor: pointer;
	&:hover,
	&:focus {
		background-color: darken(#005397, 3%) !important;
	}
}

.slick-dots {
	li {
		height: 10px;
		width: 10px !important;
	}
}
// .slick-dots .slick-active {
// 	background-color: #ff8788;
// 	width: 8px !important;
// 	height: 8px;
// 	border-radius: 8px;
// }
.slick-dots li button:before {
	margin-top: 15px;
	color: #ff8788 !important;
	font-size: 10px !important;
}
