//Variables
//author: @Anisat Yunusa

// Colors
$primary: #ff8788;
$primary-light-1: #fff3f3;
$primary-light-2: #fffcfc;

$secondary: #3d0814;

$black: #000b14;
$gray: #5c6368;
$off-white: #f5f5f5;
$ligh-blue: #f5fbff;

$success: #3bad54;
$success-light: #ebf7ee;
$pending: #f79f1a;
$pending-light: #fef1dd;
$danger: #e0063c;

$f-blue: #005397;
$f-lime: #e7f9a9;
$f-green: #9a9b73;

$stroke-alt: rgba(#d3d3d3, 0.4);

$dropshadow: 0px 0px 10px rgba(0, 11, 20, 0.04);
$borderinput: 1px solid $stroke-alt;
$borderline: 1px solid $off-white;

//RADIUS
$preferredradius: 8px;
$radius-c: 50%;
$radius-10: 10px;

// MEDIA QUERIES
$xxsmallup: 'only screen and (min-width: 312px)';
$xxsmalldown: 'only screen and (max-width: 320px)';
$xsmallup: 'only screen and (min-width: 368px)';
$xsmalldown: 'only screen and (max-width: 368px)';
$xs-md-up: 'only screen and (min-width: 480px)';
$xs-md-down: 'only screen and (max-width: 480px)';
$smallup: 'only screen and (min-width: 576px)';
$smalldown: 'only screen and (max-width: 575.98px)';
$mediumup: 'only screen and (min-width: 768px)';
$mediumdown: 'only screen and (max-width: 768.98px)';
$semidown: 'only screen and (max-width: 868.98px)';
$largeup: 'only screen and (min-width: 992px)';
$largedown: 'only screen and (max-width: 1024px)';
$slaptop: 'only screen and (min-width: 1200px)';
$slaptopdown: 'only screen and (max-width: 1200px)';
$xlargeup: 'only screen and (min-width: 1199.98px)';
$xxlargeup: 'only screen and (min-width: 1440px)';
