.topnav {
	width: 84%;
	height: 80px;
	background: #ffffff;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.03);
	position: fixed;
	top: 0;
	right: 0;

	// background: #fff;
	// box-shadow: 0 1px 5px rgba(0, 0, 0, 0.03);
	// height: 80px;
	// padding: 0 1.5rem;
	// position: fixed;
	// right: 0;
	// top: 0;
	// width: 100%;
	z-index: 300;

	// padding: 0 1.5rem;
	// position: relative;
	@media #{ $largedown } {
		width: 100%;
	}

	&--wrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		// max-width: 1150px;
		padding: 0 40px;
		width: 100%;
		height: 80px;
		margin: auto;

		// align-items: center;
		// display: flex;
		// flex-wrap: wrap;
		// height: inherit;
		// justify-content: space-between;
		// margin: auto;
		// max-width: 1150px;
		// width: 100%;
		@media #{ $largedown } {
			padding: 0 24;
		}
	}

	.brand {
		position: relative;

		h2 {
			font-weight: 500;
			font-size: 1.12rem;
			line-height: 22px;
			color: #000000;
			text-transform: capitalize;
			@media #{ $smalldown } {
				display: none;
			}
		}
		svg {
			display: none;
			cursor: pointer;
		}
		@media #{ $largedown } {
			display: flex;
			align-items: center;
			gap: 40px;
			svg {
				display: block;
			}
		}
	}

	.topnav-right {
		position: relative;
		padding: 1rem 0;
		cursor: pointer;
	}

	.username {
		position: relative;
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: 19px;
		text-align: right;

		@media #{ $smalldown } {
			display: none;
		}
	}

	&__dropdown {
		position: absolute;
		z-index: 1;
		right: 0;
		top: 100%;
		padding: 0.5rem 0;
		min-width: 180px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
		box-sizing: border-box;

		li {
			width: 100%;
			border-radius: 0;
			font-size: 0.875rem;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: 0.004em;
			color: $gray;
			display: flex;

			&:hover {
				background: $off-white;
			}

			a,
			span {
				width: 100%;
				padding: 0.5rem 1.5rem;
				color: inherit;
			}

			.logoutBtn {
				color: $danger;
			}
		}
	}
}

.singleNav {
	width: 100%;

	&--wrapper {
		padding: 0 120px;
	}
}

.avatar-circle {
	position: relative;
	height: 48px;
	width: 48px;
	border-radius: 100%;
	background: $primary-light-2;
	border: 1px solid $primary;
	margin-right: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;

	.image-wrapper,
	&__placeholder {
		position: relative;
		height: 44px;
		width: 44px;
		border-radius: 100%;
	}

	.image-wrapper img {
		position: relative;
		border-radius: 100%;
		width: 100%;
		object-fit: cover;
		height: 100%;
		max-height: 100%;
	}

	&__placeholder {
		background: $primary-light-1;
	}

	&__initials {
		position: relative;
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}
}
.table_circle {
	width: 40px;
	height: 40px;
	border: none;
	margin-right: 0.5rem;
	p {
		font-style: normal;
		font-weight: 500;
		font-size: 0.875rem;
		line-height: 17px;
		color: #3d0814;
	}
}
