.plan {
	padding: 40px;
	// position: relative;
	height: calc(100vh - 80px);
	@media #{ $largedown } {
		padding: 24px;
	}
	&__header {
		h1 {
			font-style: normal;
			font-weight: 600;
			font-size: 1.13rem;
			line-height: 22px;
			color: $black;
		}
		P {
			font-style: normal;
			font-weight: 400;
			font-size: 0.87rem;
			letter-spacing: 0.004em;
			color: $gray;
			margin-top: 4px;
		}
		.__filter {
			margin-top: 24px;
			ul {
				display: flex;
				gap: 8px;
				flex-wrap: wrap;
				li {
					font-style: normal;
					font-weight: 500;
					font-size: 0.87rem;
					line-height: 17px;
					color: #3d0814;
					background: #f5f5f5;
					border-radius: 100px;
					padding: 8px 24px;
					cursor: pointer;
					transition: all 0.5s;
					&:hover {
						background: darken($color: #f5f5f5, $amount: 9);
					}
				}
				.active {
					background: #005397;
					color: #ffffff;
					&:hover {
						background: lighten($color: #005397, $amount: 5);
					}
				}
			}
		}
	}
	&__body {
		margin-top: 32px;
		display: grid;
		// flex-wrap: wrap;
		gap: 24px;
		width: 100%;
		padding-bottom: 40px;
		@media (min-width: 600px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 900px) {
			grid-template-columns: repeat(3, 1fr);
		}
		.plan__card {
			// width: 355px;
			border: 1px solid #f5f5f5;
			border-radius: 10px;
			background-image: url('../../assets/svgs/Group.svg');
			background-repeat: no-repeat;
			background-position-x: right;
			// @media #{ $largedown } {
			// 	width: 302px;
			// }
			// &:last-child {
			// 	margin-bottom: 40px;
			// }
			&--header {
				display: flex;
				align-items: center;
				gap: 12px;
				padding: 17px;
				img {
					height: 32px;
					width: 32px;
				}
				.header__content {
					// width: 120px;
					// height: 20px;
					// overflow: hidden;
					h2 {
						font-style: normal;
						font-weight: 500;
						font-size: 0.88rem;
						line-height: 17px;
						color: $black;
						text-transform: uppercase;
					}
					p {
						font-style: normal;
						font-weight: 400;
						font-size: 0.75rem;
						line-height: 15px;
						color: $gray;
						span {
							font-weight: 500;
							color: #005397;
							text-decoration: underline;
							cursor: pointer;
						}
					}
				}
			}
			&--body {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 17px;
				p {
					font-style: normal;
					font-weight: 500;
					font-size: 0.75rem;
					line-height: 15px;
					color: $black;
				}
				.price {
					font-style: normal;
					font-weight: 600;
					font-size: 1.125rem;
					line-height: 22px;
					color: $black;

					span {
						font-weight: 500;
						font-size: 0.87rem;
						color: $gray;
					}
				}
			}
			&--bottom {
				display: flex;
				margin-top: 16px;
				// padding-top: 17px;
				align-items: center;
				background: #fff3f3;
				border-radius: 10px;
				.plan__btn {
					width: 50%;
					background: #fff3f3;
					backdrop-filter: blur(15px);
					border: none;
					outline: none;
					height: 48px;
					font-style: normal;
					font-weight: 500;
					font-size: 0.875rem;
					line-height: 150%;
					transition: all 0.5s;

					color: #005397;
					&:first-child {
						//border-right: 1px solid #ffdddd;
						border-bottom-left-radius: 10px;
						position: relative;
						transition: 0.3s ease-in-out;
						overflow: hidden;
						.btn-text {
							position: absolute;
							z-index: 3;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
						}
						.cart-i {
							position: absolute;
							z-index: 3;
							top: 50%;
							left: -10%;

							transform: translate(-50%, -50%);
							svg {
								font-size: 1.5rem;
							}
						}
						.box-i {
							position: absolute;
							z-index: 2;
							top: -20%;
							left: 52%;
							font-size: 0.875rem;
							transform: translate(-50%, -50%);
						}
						.add-to-cart {
							opacity: 1;
						}
						.added {
							opacity: 0;
						}
					}
					&:last-child {
						border-bottom-right-radius: 10px;
					}
					&:hover {
						background-color: #ffeaea;
					}
				}
				hr {
					border: none;
					border-right: 1px solid #ffdddd;
					height: 40px;
				}
			}

			// .sidebar {
			// 	grid-area: sidebar;
			// 	background-color: red;
			// 	transform: translateX(-100%);
			// 	transition: 200ms ease;
			// 	z-index: 1;
			// 	position: relative;
			// }

			// .collapsed {
			// 	transform: translateX(0%);
			// }
		}
	}
	.cart {
		background-color: #005397;
		width: 92px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
		position: absolute;
		bottom: 60px;
		right: 60px;
		cursor: pointer;
		&__details {
			display: flex;
			gap: 5px;
			align-items: center;
			p {
				font-style: normal;
				font-weight: 500;
				font-size: 1rem;
				line-height: 19px;
				color: #ffffff;
			}
		}
	}

	.backdrop {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		z-index: 9999999999;
		background: rgba(0, 11, 20, 0.85);
	}
	.modal__content {
		p {
			font-weight: 400;
			font-size: 1rem;
			line-height: 150%;
			color: $gray;
		}
		.btn {
			margin-top: 40px;
		}
	}

	.planCreation {
		display: flex;
		justify-content: space-between;
		padding-bottom: 40px;
		@media #{ $semidown } {
			flex-direction: column;
			gap: 30px;
		}
		.addEmployees__section {
			width: 50%;
			@media #{ $semidown } {
				width: 100%;
			}
			&--header {
				h2 {
					font-weight: 600;
					font-size: 1.125rem;
					line-height: 22px;
					color: $black;
					margin-bottom: 4px;
				}
				p {
					font-weight: 400;
					font-size: 0.875rem;
					line-height: 24px;
					letter-spacing: 0.004em;
					color: $gray;
				}
				.invite {
					font-weight: 500;
					font-size: 1rem;
					line-height: 150%;
					text-decoration-line: underline;
					margin-top: 24px;
					color: #3d0814;
					cursor: pointer;
				}
			}
			&--body {
				margin-top: 40px;
				border: 1px solid #f5f5f5;
				border-radius: 10px;
				height: 567px;
				overflow: scroll;

				.input_section {
					padding: 24px 16px;
					padding-bottom: 0px;
					position: relative;
					.input__dropdown {
						position: absolute;
						top: 64px;
						width: 94%;
						height: 260px;
						overflow: scroll;
						background: #ffffff;
						border: 1px solid #ffffff;
						box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.04);
						border-radius: 10px;
						left: 18px;
						z-index: 1;
						&--header {
							border-bottom: 1px solid #f5f5f5;
							padding: 16px 24px;
							.__search {
								display: flex;
								align-items: center;
								gap: 8px;
								width: 100%;
								height: 40px;
								background: #ffffff;
								border: 1px solid #d3d3d3;
								border-radius: $preferredradius;
								padding: 12px;
								opacity: 0.6;
								padding: 0px 21px;
								// svg {
								// 	opacity: 0.4;
								// }
								@media #{ $xs-md-down } {
									width: 100%;
								}
								input {
									outline: none;
									border: none;
									// &::placeholder {
									// 	opacity: 0.4;
									// }
									//
									font-weight: 400;
									font-size: 1rem;
									line-height: 19px;
									color: $black;
									width: 98%;
								}
							}
						}
						&--body {
							padding: 16px 24px 0;
							overflow: scroll;
							> p {
								font-weight: 500;
								font-size: 1rem;
								line-height: 150%;
								text-decoration-line: underline;
								color: #3d0814;
								cursor: pointer;
								margin-bottom: 8px;
							}
							.__employee--list {
								padding: 16px;
								display: flex;
								gap: 16px;
								border-bottom: 1px solid #f5f5f5;

								> input {
									width: 14px;
									height: 16px;
									margin-top: 3px;
									cursor: pointer;
								}
								.name {
									h4 {
										font-weight: 500;
										font-size: 1rem;
										line-height: 24px;
										letter-spacing: 0.004em;
										color: $black;
									}
									p {
										font-weight: 400;
										font-size: 0.75rem;
										line-height: 24px;
										/* identical to box height, or 200% */

										letter-spacing: 0.004em;

										/* f-gray */

										color: $gray;
									}
								}
							}
						}
					}
					.employee-input {
						width: 100%;
						opacity: 0.4;
						border: 1px solid #d3d3d3;
						border-radius: 8px;
						height: 40px;
						padding: 10px 24px;
						font-size: 16px;
						line-height: 19px;
						color: #717171;
					}
				}

				.pick-text {
					padding: 0px 16px;
					margin-top: 8px;
					margin-bottom: 24px;
					font-style: italic;
					font-weight: 400;
					font-size: 0.75rem;
					line-height: 24px;
					letter-spacing: 0.004em;
					color: $gray;
				}

				.table-section {
					border: 1px solid #f5f5f5;
					border-bottom: none;
					border-radius: 10px 10px 0px 0px;
				}
			}
		}
		.payment__section {
			background: #ffffff;
			box-shadow: 0px 0px 10px rgba(0, 11, 20, 0.04);
			border-radius: 10px;
			width: 437px;
			height: 705px;
			@media #{ $largedown } {
				width: 390px;
			}
			@media #{ $semidown } {
				width: 100% !important;
			}
			&--header {
				width: 100%;
				background: #fff3f3;
				border-radius: 10px 10px 0px 0px;
				padding: 24px;
				height: 152px;
				.__plan {
					display: flex;
					justify-content: space-between;
				}
				.plan-name {
					font-weight: 500;
					font-size: 1rem;
					line-height: 24px;
					.nameEdit {
						color: $black;
						border: none;
						background-color: transparent;
						font-weight: 500;
						font-size: 1rem;
						line-height: 24px;
						outline: none;
					}
					span {
						text-decoration-line: underline;
						color: #005397;
						cursor: pointer;
					}
					.checkPlanName {
						display: flex;
						gap: 5px;
					}
				}
				.plan-employee {
					margin: 16px 0px;
					font-weight: 500;
					line-height: 24px;
					color: $black;
					letter-spacing: 0.004em;
					p {
						font-size: 0.875rem;
					}
					span {
						font-size: 1rem;
					}
				}
				.plan-cycle {
					font-weight: 500;
					line-height: 24px;
					letter-spacing: 0.004em;
					color: $black;
					text-transform: capitalize;
					p {
						font-size: 0.875rem;

						svg {
							margin-left: 8px;
							cursor: pointer;
						}
					}
					.interval {
						position: relative;
						&__action {
							display: flex;
							gap: 16px;
							span {
								font-size: 1rem;
							}
							.change {
								text-decoration-line: underline;
								color: #005397;
								cursor: pointer;
							}
						}
						.intervals {
							position: absolute;
							border-radius: 10px;
							background: #ffffff;
							box-shadow: 3px 5px 20px 0px rgba(0, 0, 0, 0.04);
							width: 160px;
							height: fit-content;
							left: 0px;
							ul {
								li {
									padding: 1rem 1.5rem;
									color: $gray;
									font-size: 0.875rem;
									font-weight: 500;
									letter-spacing: 0.0035rem;
									text-transform: capitalize;
									cursor: pointer;
									&:hover {
										background-color: #f5f5f5;
									}
									&:nth-child(2) {
										padding: 0.5rem 1.5rem;
									}
								}
							}
						}
					}
				}
			}
			&--body {
				padding: 24px;
				height: 317px;
				overflow: scroll;
				.__benefit-container {
					padding: 16px 0;
					display: flex;
					justify-content: space-between;
					border-bottom: 1px solid #f5f5f5;
					h2 {
						font-weight: 500;
						font-size: 0.875rem;
						line-height: 24px;
						letter-spacing: 0.004em;
						color: $black;
						text-transform: uppercase;
						span {
							text-transform: lowercase;
						}
					}
					.benefit-name {
						p {
							font-weight: 400;
							font-size: 0.75rem;
							line-height: 24px;
							letter-spacing: 0.004em;
							color: $gray;
						}
					}
				}
			}
			&--bottom {
				border-top: 1px solid #f5f5f5;
				border-bottom: 1px solid #f5f5f5;

				height: 152px;
				padding: 24px;
				.__price {
					display: flex;
					justify-content: space-between;
					font-weight: 500;
					font-size: 0.875rem;
					line-height: 24px;
					letter-spacing: 0.004em;
					color: $gray;
					margin-bottom: 16px;
					p {
						svg {
							margin-left: 8px;
							cursor: pointer;
						}
					}
				}
				.total {
					font-weight: 600;
					color: $black;
					margin-bottom: 24px;
				}
			}
			&--btn {
				padding: 16px 24px;
			}
		}
	}
	.planlist {
		padding-bottom: 40px;
		.table-container {
			margin-top: 32px;
			// border: 1px solid #f5f5f5;
			// border-radius: 15px;
			background-color: #fff;
			border: 1px solid $off-white;
			border-radius: 15px;
		}
		> .plan__status {
			display: grid;
			margin-top: 0;
			gap: 24px;
			width: 100%;
			padding-bottom: 40px;
			padding-top: 1rem;
			@media (min-width: 600px) {
				grid-template-columns: repeat(1, 1fr);
			}
			@media (min-width: 900px) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
	.planDetails {
		padding-bottom: 50px;
		> .table-container {
			border: 1px solid #f5f5f5;
			border-radius: 10px;
			> h2 {
				font-weight: 500;
				font-size: 0.875rem;
				text-transform: uppercase;
				color: $black;
				padding: 16px 32px;
			}
			> table {
				border-top: 1px solid #f5f5f5;
				th,
				td {
					background-color: #fff;
					border: none;
				}
				td {
					padding-bottom: 24px;
				}
			}
		}
		> h2 {
			font-weight: 600;
			font-size: 1.125rem;
			line-height: 22px;
			color: $black;
		}
		> p {
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 24px;
			letter-spacing: 0.004em;
			color: $gray;
		}
		&__benefits {
			margin-top: 1.5rem;
			> h4 {
				color: #5c6368;
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 600;
				line-height: 1.25rem; /* 166.667% */
				text-transform: uppercase;
				> span {
					font-weight: 400;
					font-style: italic;
				}
			}
			.benefits__categoryies {
				display: grid;
				margin-top: 0;
				gap: 24px;
				width: 100%;
				padding-bottom: 40px;
				padding-top: 1rem;
				@media (min-width: 600px) {
					grid-template-columns: repeat(2, 1fr);
				}
				@media (min-width: 900px) {
					grid-template-columns: repeat(3, 1fr);
				}
				.category {
					display: flex;
					justify-content: space-between;
					border-radius: 1.875rem;
					border: 1px dashed #ff8788;
					background: #fff;
					padding: 0.75rem 1.5rem;
					// margin-top: 1rem;
					.category__name {
						display: flex;
						align-items: center;
						gap: 0.5rem;

						> p {
							color: $black;
							font-size: 0.875rem;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
						}
					}
					> span {
						color: #3d0814;
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-decoration-line: underline;
						&:hover {
							cursor: pointer;
						}
					}
				}
				.add__benefits {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-radius: 1.875rem;
					border: 1px dashed rgba(92, 99, 104, 0.4);
					background: #fff3f3;
					padding: 0.75rem 1.5rem;
					width: 15rem;
					cursor: pointer;
					transition: all 0.2s;
					> span {
						background-color: #fff;
						border-radius: 50%;
						width: 24px;
						height: 24px;
						// display: block;
						display: flex;
						justify-content: center;
						align-items: center;
					}
					> p {
						color: #000b14;
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
					&:hover {
						background-color: #ff8788;
					}
				}
			}
		}
		&__employees {
			margin-top: 48px;

			> .add-employee {
				margin: 24px 0px;
			}
			> .table-container {
				border: 1px solid #f5f5f5;
				border-radius: 15px;
			}
		}
		&__billings {
			margin-top: 48px;
			> .table-container {
				margin-top: 24px;
				border: 1px solid #f5f5f5;
				border-radius: 15px;
			}
		}
	}
}
.addToPlan {
	&__header {
		display: flex;
		justify-content: space-between;
		@media #{ $mediumdown } {
			flex-direction: column;
			gap: 10px;
		}
		&--search {
			display: flex;
			align-items: center;
			gap: 8px;
			min-width: 25rem;
			height: 40px;
			background: #ffffff;
			border: 1px solid #d3d3d3;
			border-radius: $preferredradius;
			padding: 12px;
			opacity: 0.8;
			// svg {
			// 	opacity: 0.4;
			// }
			@media #{ $xs-md-down } {
				width: 100%;
			}
			input {
				outline: none;
				border: none;
				// &::placeholder {
				// 	opacity: 0.4;
				// }
				//
				font-weight: 400;
				font-size: 1rem;
				line-height: 19px;
				color: $black;
				width: 95%;
			}
		}
		&--btn {
			width: 6.25rem;
			height: 40px;
		}
	}
	&__body {
		overflow: scroll;
		height: 250px;
		margin-top: 1.12rem;
		.select__all {
			display: flex;
			gap: 10px;
			margin-top: 16px;
			> label {
				font-weight: 500;
				font-size: 0.85rem;
				line-height: 150%;
				text-decoration-line: underline;
				color: #3d0814;
				cursor: pointer;
				margin-bottom: 8px;
				display: block;
			}
			> input {
				width: 0.875rem;
				height: 1.3rem;
				cursor: pointer;
			}
		}
		.__list {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #f5f5f5;
			align-items: center;
			cursor: pointer;
			&:hover {
				background-color: #f5f5f5 !important;
			}
			.name__details {
				margin: 16px 0px;
				display: flex;

				.name {
					h4 {
						font-weight: 500;
						font-size: 1rem;
						line-height: 24px;
						letter-spacing: 0.004em;
						color: $black;
						text-transform: capitalize;
					}
					p {
						font-weight: 400;
						font-size: 0.75rem;

						letter-spacing: 0.004em;

						/* f-gray */

						color: $gray;
					}
				}
			}

			> input {
				width: 14px;
				height: 16px;
				margin-top: 3px;
				cursor: pointer;
			}
		}
	}
}

.no_activePlan {
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	&--container {
		width: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@media #{ $xs-md-down } {
			width: 300px242;
		}

		img {
			width: 162px;
			height: 179px;
			@media #{ $smalldown } {
				width: 132px !important;
				height: 150px !important;
			}
		}
		.dimension {
			width: 242px;
			height: 128px;
		}
		> p {
			width: 100%;
			text-align: center;
			font-style: normal;
			font-weight: 400;
			font-size: 1rem;
			line-height: 160%;
			color: $gray;
			margin-top: 24px;
		}
		.btn_container {
			width: 85%;
		}
	}
}
.centerHeight {
	height: calc(100vh - 80px);
}
.plan__status {
	display: grid;
	margin-top: 0;
	gap: 24px;
	width: 100%;
	padding-bottom: 40px;
	padding-top: 1rem;
	@media (min-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
	}
	@media (min-width: 900px) {
		grid-template-columns: repeat(2, 1fr);
	}
}
.plan__status--container {
	// min-width: 18.1875rem;
	border-radius: 0.625rem;
	border: 1px solid #f5f5f5;
	background: #fff;
	padding: 0.75rem 1.5rem;
	> h4 {
		color: #5c6368;
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.25rem; /* 166.667% */
		text-transform: uppercase;
		margin-bottom: 0.5rem;
	}
	> .status {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 0.5rem;
		> p {
			text-align: center;
			font-size: 0.75rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			border-radius: 0.25rem;
			width: 4.35rem;
			padding: 0.15rem 0rem;
			text-transform: capitalize;
		}
		.active {
			color: #3bad54;
			background: #f1fef4;
		}
		.pending {
			color: #f79f1a;
			background: #fef1dd;
		}
		.expired {
			color: #e0063c;
			background: #fff3f3;
		}
		.inactive {
			color: rgba(92, 99, 104, 0.85);
			background-color: #f5f5f5;
		}
	}
	.__details {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		font-size: 0.875rem;
		> p {
			color: rgba(92, 99, 104, 0.9);

			font-style: normal;
			font-weight: 500;
			line-height: normal;
			> span {
				color: #000b14;
				font-size: 1.5rem;
				font-weight: 600;
			}
		}
		> span {
			color: #005397;
			cursor: pointer;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.0175rem;
			text-decoration-line: underline;
			text-transform: uppercase;
		}
	}
}
.no__benefits {
	margin-top: 30px;
}

// btn animations

.clicked {
	.cart-i {
		animation: cart 1.5s ease-in-out forwards;
	}
	.box-i {
		animation: box 1.5s ease-in-out forwards;
	}
	.add-to-cart {
		animation: txt1 1.5s ease-in-out forwards;
	}
	.added {
		animation: txt2 1.5s ease-in-out forwards;
	}
}

@keyframes cart {
	0% {
		left: -10%;
	}
	40%,
	60% {
		left: 50%;
	}
	100% {
		left: 110%;
	}
}
@keyframes box {
	0%,
	40% {
		top: -20%;
	}
	60% {
		top: 40%;
		left: 52%;
	}
	100% {
		top: 40%;
		left: 112%;
	}
}
@keyframes txt1 {
	0% {
		opacity: 1;
	}
	20%,
	100% {
		opacity: 0;
	}
}
@keyframes txt2 {
	0%,
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.noEmployee__container {
	width: 313px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	margin: auto;
	img {
		width: 121.06px;
		height: 90px;
	}
	h4 {
		font-size: 16px;
		font-weight: 500;
		line-height: 19.36px;
		letter-spacing: 0.3199999928474426px;
		text-align: center;
		padding-top: 12px;
	}
	p {
		font-weight: 400;
		font-size: 0.875rem;
		line-height: 160%;
		text-align: center;
		color: #5c6368;
	}
}
