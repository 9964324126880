.empoyeeSignUp {
	background-color: $ligh-blue;
	// padding-top: 3.5rem;
	padding-bottom: 10rem;
	.brand {
		padding: 3.5rem 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 6.39275rem;
			height: 1.875rem;
		}
	}

	.employeeSignUp__container {
		border-radius: 0.625rem;
		background-color: #ffffff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.02);
		width: 50%;
		margin: 0 auto;
		@media #{ $largedown } {
			width: 70%;
		}
		@media #{ $mediumdown } {
			width: 80%;
		}
		@media #{ $smalldown } {
			width: 85%;
		}
		@media #{ $smalldown } {
			width: 90%;
		}

		.employee__form {
			padding: 2.5rem 2.5rem;
			.employee--content {
				h3 {
					color: $black;
					font-size: 1.5rem;
					font-style: normal;
					font-weight: 600;
					text-align: center;
				}
				p {
					color: $gray;
					text-align: center;
					font-size: 1rem;
					font-style: normal;
					font-weight: 400;
					line-height: 150%;
				}
			}
		}
	}
}

.__form-employeeSignup {
	margin-top: 2rem;
}
.acknoledgment {
	color: #5c6368;
	text-align: center;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.125rem */
	letter-spacing: 0.015rem;
	a {
		text-decoration: underline;
	}
}
