//AUTH LAYOUT
//author: @Anisat Yunusa

.auth {
	position: relative;

	@media #{ $mediumup } {
		display: flex;
		height: 100vh;
		overflow: hidden;
	}

	&__aside {
		position: relative;
		padding: 0 1.5rem;
		background-color: #ffffff;

		@media #{ $mediumup } {
			width: 45%;
			background-color: $ligh-blue;
		}

		@media #{ $slaptop } {
			width: 42%;
		}

		&--content {
			position: relative;
			max-width: 498px;
			width: 100%;
			margin: auto;
			margin-top: 2.5rem;
			@media #{ $xxsmalldown } {
				margin-top: 2.5rem;
			}
		}

		&--header {
			position: relative;
			margin-top: 4.25rem;

			@media #{ $mediumdown } {
				display: none;
			}

			h1 {
				font-size: 2.25rem;
				font-weight: 600;
				line-height: 54px;
				color: $black;
				@media #{ $largedown } {
					font-size: 1.875rem;
					line-height: 40px;
				}
			}

			p {
				font-size: 1.125rem;
				font-weight: 500;
				line-height: 27px;
				color: $gray;
				margin-top: 1.5rem;
				@media #{ $largedown } {
					font-size: 1rem;
					line-height: 28px;
				}
			}
		}

		&--images {
			position: relative;

			@media #{ $mediumdown } {
				display: none;
			}
			margin-top: 5rem;
			.carousel__container {
				padding: 24px;
				background-color: #ffffff;
				backdrop-filter: blur(14px);
				width: 100%;
				height: 220px;
				border: 1.5px solid #ffffff;
				border-radius: 16px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				&--header {
					p {
						font-style: normal;
						font-weight: 400;
						font-size: 1rem;
						line-height: 150%;
						color: $gray;
						text-align: left;
					}
				}
				&--footer {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					.name {
						h4 {
							font-style: normal;
							font-weight: 500;
							font-size: 0.87rem;
							line-height: 150%;
							color: $black;
							text-align: left;
						}
						p {
							font-style: normal;
							font-weight: 400;
							font-size: 0.6rem;
							line-height: 150%;
							color: $gray;
							text-align: left;
						}
					}
				}
			}
		}
	}

	&__form {
		position: relative;
		flex: 1;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 1.5rem;
		height: inherit;
		overflow: hidden;
		overflow-y: scroll;
		@media #{ $mediumdown } {
			// justify-content: center;
			width: 100%;
		}

		&--content {
			position: relative;
			max-width: 586px;
			width: 100%;
			padding: 9.25rem 0 3rem 0;
			@media #{ $mediumdown } {
				padding-top: 3.5rem;
				position: unset;
			}
			.mail_icon {
				// padding: 14px;
				svg {
					background-color: $primary-light-1;
					border-radius: $radius-c;
				}
			}
		}

		&--header {
			position: relative;
			.header__container {
				display: flex;
				justify-content: space-between;
				@media #{ $smalldown } {
					flex-direction: column-reverse;
					gap: 20px;
				}
				&--content {
					h3 {
						position: relative;
						font-size: 1.75rem; //28px
						font-weight: 600;
						line-height: 34px;
						@media #{ $smalldown } {
							font-size: 1.5rem;
						}
					}

					p {
						position: relative;
						font-size: 1rem;
						font-weight: 400;
						line-height: 24px;
						letter-spacing: 0.01em;
						color: $gray;
						margin-top: 0.5rem;

						@media #{ $smalldown } {
							font-size: 0.875rem;
						}
					}
				}
			}
			span {
				font-weight: 400;
				font-size: 1rem;
				letter-spacing: 0.01em;
				color: $gray;
			}
		}

		.sso {
			position: relative;

			.bolt-signup-btn {
				gap: 1rem;

				img {
					width: 24px;
					height: 24px;
				}
			}

			p {
				position: relative;
				text-transform: uppercase;
				text-align: center;
				margin-top: 2rem;
				margin-bottom: 2rem;
				color: $gray;

				&:before {
					content: '';
					display: block;
					width: 40%;
					height: 1px;
					background: rgba($color: $gray, $alpha: 0.3);
					left: 5%;
					top: 50%;
					position: absolute;
				}

				&:after {
					content: '';
					display: block;
					width: 40%;
					height: 1px;
					background: rgba($color: $gray, $alpha: 0.3);
					right: 5%;
					top: 50%;
					text-align: left;
					position: absolute;
				}
			}
		}

		.extralinnk {
			font-size: 1rem;
			font-weight: 500;
			line-height: 24px;
			color: $gray;
		}

		.acknwmnt {
			position: relative;
			max-width: 460px;
			width: 100%;
			margin: auto;
			margin-top: 3.5rem;
			font-size: 0.75rem;
			line-height: 18px;
			letter-spacing: 0.02em;
			color: $gray;

			a {
				font-size: inherit;
				color: inherit;
				&:hover {
					color: $secondary;
				}
			}
		}
	}

	&__success {
		position: relative;
		height: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: 2rem 0;
		@media #{ $mediumdown } {
			padding-top: 4rem;
		}

		&-container {
			max-width: 520px;
		}

		h3 {
			font-size: 1.25rem;
			font-weight: 600;
			line-height: 24px;
			margin-top: 2rem;
		}

		p {
			font-size: 1rem;
			font-weight: 400;
			line-height: 24px;
			color: $gray;
			margin-top: 1rem;
		}
	}
}
.carousel .control-dots {
	bottom: -50px !important;
}
.carousel .control-dots .dot {
	margin-top: 20px !important;
	background: #ff8788 !important;
	box-shadow: none !important;
}

.carousel.carousel-slider {
	overflow: visible !important;
}

.__form {
	position: relative;
	width: 100%;
	margin-top: 3.5rem;
	@media #{ $mediumdown } {
		margin-top: 2.5rem;
	}

	&-signup {
		margin-top: 3rem;

		&-tip {
			display: flex;
			background: #f8fcff;
			border-radius: $preferredradius;
			padding: 1rem 1.9rem;
			gap: 16px;
			margin-top: 1.5rem;
			span {
				display: block;
			}
			P {
				font-weight: 400;
				font-size: 0.875rem;
				line-height: 150%;
				color: $black;
			}
			// justify-content: space-between;
		}
	}
}
