table {
	width: 100%;
	overflow-x: scroll;
	// background-color: $primary-light-2;
	border-radius: 15px;
	border-collapse: collapse;
	@media #{ $mediumdown } {
		display: none;
	}

	// background-color: red;
	// padding: 0 32px;
	// padding-right: 20px;
	tr {
		height: 56px;
		border-radius: 15px;
		padding: 0 32px;

		th {
			border: none;
			border-bottom: 1px solid #f5f5f5;
			background: $primary-light-2;
			text-align: left;
			padding: 0 32px;
			font-weight: 600;
			font-size: 0.75rem;
			line-height: 15px;
			letter-spacing: 0.004em;
			text-transform: uppercase;
			color: $gray;
			border: none;
			&:first-child {
				border-top-left-radius: 15px;
			}
			&:last-child {
				border-top-right-radius: 15px;
			}

			// border-collapse: collapse;
		}
		.blueHeader {
			background-color: #f5fbff;
		}
		.transparentHeader {
			background-color: #fff;
		}
	}

	tr {
		&:hover {
			background-color: #f5f5f5 !important;
		}

		.delete-row {
			color: #e0063c;

			&:hover {
				text-decoration: underline;
				font-weight: 500;
			}
		}

		td {
			position: relative;
			padding: 8px 0px;
			padding-left: 32px;
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 17px;
			color: rgba(0, 11, 20, 0.8);
			// background-color: #ffffff;
			border-bottom: 1px solid #f5f5f5;
			justify-content: left;
			text-transform: capitalize;
			transition: 0.3s;
			&:last-child {
				cursor: pointer;
			}
		}
		.plan-table {
			padding: 16px 0px;
			padding-left: 32px;
			border-top: 1px solid #f5f5f5;
			border-bottom: none;
			text-transform: capitalize;
		}
		// .duration {
		// }
		.status {
			padding: 8px 16px;
			border-radius: 100px;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 0.75rem;
			text-transform: uppercase;
		}
		.employee-active {
			background: #ebf7ee;

			color: #3bad54;
		}
		.employee-active {
			background: #ebf7ee;
			color: #3bad54;
		}
		.employee-inactive {
			background: #f5f5f5;
			color: $gray;
		}
		.pending {
			color: #f79f1a;
			background: #fef1dd;
		}
	}
}
.grey-bg {
	transition: 0.5s;
	&:hover {
		background-color: #f5f5f5 !important;
	}
}

.smaller__table {
	display: none;
	@media #{ $mediumdown } {
		display: block;
		padding: 0px 24px;
		.list-container {
			border-bottom: 1px solid #f5f5f5;
			.table__head {
				position: relative;
				margin-top: 16px;
				display: flex;
				justify-content: space-between;
			}
			p {
				font-weight: 400;
				font-size: 0.87rem;
				line-height: 17px;
				color: rgba(0, 11, 20, 0.8);
				// opacity: 0.8;
				padding: 16px 0;
			}
		}
	}
}
