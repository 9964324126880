.wallet {
	padding: 40px;
	@media #{ $mediumdown } {
		padding: 24px;
	}
	.wallet__card {
		max-width: 23.125rem;
		height: 11.875rem;
		display: flex;
		padding: 1.5rem 2rem;
		border-radius: 0.625rem;
		background: #3d0814;
		align-items: flex-end;
		background-image: url('../../assets/svgs/pttrn.svg');
	}
	.wallet__card--container {
		> .header {
			display: flex;
			gap: 0.5rem;
			> h4 {
				color: #f5f5f5;
				font-size: 0.75rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: uppercase;
			}
			svg {
				color: #fff3f3;
				cursor: pointer;
				vertical-align: #fff3f3;
				fill: #fff3f3;
			}
		}
		> .amount {
			margin-top: 1rem;
			color: #f5f5f5;
			font-size: 1.75rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
	.wallet__table {
		margin-top: 2.5rem;
		> h2 {
			color: #000b14;
			font-size: 1.125rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		> p {
			color: #5c6368;
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.5rem; /* 171.429% */
			letter-spacing: 0.0035rem;
			margin-bottom: 1.5rem;
		}
	}
	.noTransactions__container {
		width: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		margin: auto;
		margin-top: 3rem;
		img {
			width: 250.06px;
			height: 120px;
		}
		p {
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 160%;
			text-align: center;
			color: #5c6368;
			margin-top: 1.5rem;
		}
	}
}
