//SIDENAV
//author: @Anisat Yunusa

.sidebar--container {
	width: 16%;
	height: 100vh;
	background: #f5fbff;

	@media #{ $largedown } {
		// padding-bottom: 0;
		display: none;
		// transition: all 0.5s;
	}
	.dashboard__sidenav {
		padding: 1.5rem 1rem;

		border-right: 1px solid #edeff5;
		height: 100%;
		width: 16%;
		background: #f5fbff;

		position: fixed;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		// @media #{ $largedown } {
		// 	// padding-bottom: 0;
		// 	display: none;
		// 	transition: all 0.5s;
		// }
		.dashboard__sidenav--banner {
			padding: 0 1.5rem;
			margin-bottom: 1.5rem;
			img {
				width: 102.28px;
				height: 30px;
			}
		}

		ul {
			position: relative;
			display: flex;
			flex-direction: column;

			.navlinks {
				display: flex;
				align-items: center;
				gap: 16px;
				width: 100%;
				padding: 1rem 1.5rem;
				margin-bottom: 8px;
				font-weight: 500;
				font-size: 1rem;
				line-height: 19px;
				color: #005397;
				cursor: pointer;

				&:hover {
					background: #e7f5ff;
				}

				&:first-child {
					margin-top: 20px;
				}
				&.active {
					background: #e7f5ff;
					border-radius: 6px;
					color: #005397;
					&:hover {
						background: darken(#e7f5ff, 3);
					}
				}
			}
		}
	}
}

// Sidebar slide
.sidebar-slide {
	background-color: #f5fbff !important;
}
.sidebarContainer {
	// text-align: center;
	width: 50%;
	.sidebar__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// padding: 0 20px;
		// width: 80%;
		box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.03);
		// margin: 20px auto;
		margin: 0px 40px;
		height: 12vh;
		> span {
			height: 30px;
			width: 30px;
			cursor: pointer;
		}
	}
	.close-btn {
		padding: 40px;
		cursor: pointer;
		svg {
			font-size: 2rem;
		}
	}
	.sidebar__links {
		width: 100%;
		ul {
			width: 80%;
			margin: 0 auto;
			.navlinks {
				display: flex;
				align-items: center;
				gap: 16px;
				width: 100%;
				padding: 1rem 1.5rem;
				color: $gray;
				margin-bottom: 8px;
				font-weight: 500;
				font-size: 1rem;
				line-height: 19px;
				color: $gray;
				cursor: pointer;
				&:hover {
					background: #e7f5ff;
				}
				&:first-child {
					margin-top: 20px;
				}
				&.active {
					background: #e7f5ff;
					border-radius: 6px;
					color: #005397;
				}
			}
		}
	}

	.sidebar-slide {
		position: fixed;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		background: var(--clr-white);
		transition: all 0.5s;
		transform: translate(-100%);
		z-index: -1;
		width: 100vw;
	}

	ul {
		li {
			list-style: none;
			margin-bottom: 30px;
			cursor: pointer;
			position: relative;
			padding-bottom: 6px;
			transition: all 1s;
			a {
				text-decoration: none;
				font-family: var(--primary-font);
				font-weight: 800;
				font-size: 1.1rem;
				color: var(--clr-black);
			}
			&:hover {
				color: #0a0a00;
			}
		}
	}

	.show-sidebar {
		transform: translate(0);
		z-index: 999;
		width: 50%;
		@media #{ $smalldown } {
			width: 100%;
		}
	}
	.sidebar__buttons {
		display: flex;
		justify-content: center;
	}
	@media screen and (min-width: 1024px) {
		.sidebar-slide {
			display: none;
		}
	}
}
.backdrop {
	@media screen and (max-width: 1024px) {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		z-index: 999999999;
		background: rgba(0, 11, 20, 0.85);
	}
}

.partner {
	background-color: #005397;
	border-radius: 12px;
	padding: 16px;
	&__header {
		display: flex;
		gap: 12px;
		align-items: center;
		color: #fff;
		font-size: 1rem;
		font-weight: 600;
		line-height: 24px;
		text-align: left;
		margin-bottom: 12px;
	}
	&--btn {
		background-color: #005397;
		display: flex;
		justify-content: center;
		padding: 16px;
		gap: 12px;
		border-radius: 8px;
		color: #ffffff;
		cursor: pointer;
		align-items: center;
		transition: all 0.4s;
		&:hover {
			background-color: darken(#005397, 6%);
		}
	}
}

.bottom-rule {
	border: none;

	border-bottom: 0.1px solid rgba(114, 195, 255, 0.2);

	margin: 25px 0px;
}
.docs-link {
	text-decoration: none;
	color: #005397;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.36px;
	text-align: center;
	display: flex;
	justify-content: center;
	gap: 7px;
	align-items: center;
	&:hover {
		text-decoration: underline;
	}
}
