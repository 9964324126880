//404
    //author: @Anisat Yunusa

.pagenotfound {
    position: relative;
    min-height: 100vh;

    &__content {
        position: relative;
        height: inherit;
        min-height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .icon_404 {
            max-width: 480px;
            height: auto;
        }

        p {
            position: relative;
            max-width: 528px;
            width: 90%;
            margin-top: 2.5rem;
            font-size: 1.25rem;
            line-height: 30px;
            text-align: center;

            @media #{$smalldown} {
                font-size: 1.125rem;
                margin-top: 1.25rem;
            }
        }

        .__button {
            max-width: 300px;
            width: 100%;
            align-self: center;
            margin-top: 2rem;

            .btn {
                width: 100%;
            }
        }
    }
}