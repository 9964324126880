//TEXT
//author: @Anisat Yunusa

.tx-c {
	text-align: center;
}

.tx-r {
	text-align: right;
}

.tx-medium {
	font-weight: 500;
}

.underline {
	text-decoration: underline;
	text-underline-position: under;
}

.uppercase,
.caps {
	text-transform: uppercase;
}

// .section--header {
//     position: relative;
//     font-weight: 600;
//     font-size: 1.125rem;
//     line-height: 22px;
// }

// .detail--descrip {
//     margin-top: 2rem;
//     padding-bottom: 1rem;
//     border-bottom: 1px solid $off-white;

//     h3 {
//         position: relative;
//         font-size: 1.5rem;
//         font-style: normal;
//         font-weight: 600;
//         line-height: 29px;
//     }

//     p {
//         position: relative;
//         font-size: .875rem;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 24px;
//         letter-spacing: 0.004em;
//         color: $gray;
//         margin-top: .5rem;
//         max-width: 900px;
//         width: 100%;

//         span.__value {
//             color: $f-blue;
//             font-weight: 500;
//             text-transform: uppercase;
//         }
//     }
// }

// .section--text {
//     position: relative;

//     h3 {
//         position: relative;
//         font-size: 1.125rem;
//         font-weight: 600;
//         line-height: 22px;
//     }

//     p {
//         position: relative;
//         font-size: .875rem;
//         font-weight: 400;
//         line-height: 24px;
//         letter-spacing: 0.004em;
//         color: $gray;
//         margin-top: .25rem;
//     }
// }
