// Colors
    //author: @Anisat Yunusa

.co-primary {
    color: $primary;
}

.co-gray {
    color: $gray;
}

.co-gray-60 {
    color: rgba($color: $gray, $alpha: .6);
}

.co-danger  {
    color: $danger;
}

.co-success {
    color: $success;
}

.co-blue {
    color: $f-blue;
}

.co-black {
    color: $black;
}