//SELECT CARD
//author: @Anisat Yunusa

.card-check {
	width: 100%;
	position: relative;
	display: flex;
	min-height: 48px;
	margin-bottom: 1.5rem;
	cursor: pointer;
	border: 1px solid transparent;

	&__option {
		visibility: hidden;
		display: none;
	}

	&__option:disabled + .card-check__value:hover {
		border-color: #d3d3d3;
	}

	&__value {
		position: relative;
		display: flex;
		justify-content: center;
		background: #ffffff;
		border: 1px dashed #ff8788;
		border-radius: 10px;
		width: 100%;
		font-size: 0.875rem;
		padding: 1rem 0rem;
		&:hover {
			border-color: $primary;
		}
		@media #{ $mediumdown } {
			padding-left: 3rem;
		}
		.alt & {
			min-height: 50px;
			font-weight: normal;
			color: $gray;
			border-color: transparent;
			&:hover {
				border-color: $primary;
			}
		}

		&-title {
			font-size: 1rem;
			font-weight: 500;
			line-height: 19px;
			color: $black;
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&-subtext {
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 17px;
			letter-spacing: 0.01em;
			color: $gray;
			margin-top: 0.5rem;
		}
	}
}

input:checked + .card-check__value {
	border: 1px solid $primary !important;
	background-color: $primary-light-1;
}

input:checked + .card-check__value .card-check__checkmark,
input:checked ~ .card-check__checkmark {
	border: 2px solid $primary !important;
	background: inherit;
}

input:checked + .card-check__value .card-check__checkmark:before,
input:checked ~ .card-check__checkmark:before {
	background: $primary;
	display: inline-block;
}

.card-check__leftCheckmark {
	.card-check__checkmark {
		left: unset;
		right: 20px;
	}
}

.card-check__hasIcon {
	.card-check__value {
		padding-left: 80px;
		&-icon {
			display: block;
			position: absolute;
			left: 2rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.rounded-check {
	.check-ticker {
		&__tick {
			border-radius: 50%;
			&:before {
				content: '';
				background: inherit;
				width: 70%;
				height: 70%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border: 0;
				border-radius: 50%;
				background-color: inherit;
			}
			&:after {
				display: none;
			}
		}
		input {
			&:checked + .check-ticker__tick {
				border: 2px solid $primary !important;
				background-color: #fafcff;
				box-shadow: none;
			}

			&:checked + .check-ticker__tick:before {
				background: rgba($color: $primary, $alpha: 0.5);
				display: inline-block;
			}
		}
	}
}
